import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, RequestsPath } from '@common/const';
import { showSnack, SnackbarType } from '../store/reducers/snackbarReducer';


export const getSubscriptions = createAsyncThunk(
  'subscriptionsList/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.get(RequestsPath.postSubscriptions);
      return response;
    } catch (e) {
      dispatch(showSnack({
        text: 'Ошибка при получении списка актуальных подписок. Повторите позднее...',
        type: SnackbarType.ERROR,
      }));
      return rejectWithValue(e.message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  'subscriptionsList/create',
  async (subscriptionData, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.post(`${RequestsPath.postSubscriptions}`, subscriptionData);
      dispatch(showSnack({
        text: <div>
          Подписка создана!
        </div>,
        type: SnackbarType.SUCCESS,
      }));
      return response;
    } catch (e) {
      if (e.code === 'ERR_BAD_REQUEST') {
        if (e.response.data.hashtags) {
          dispatch(showSnack({
            text: <div>
              Ошибка при создании подписки. Неверно указаны скилы. <br /> {e.response.data.hashtags[0]}
            </div>,
            type: SnackbarType.ERROR,
          }));
          return rejectWithValue(e.message);
        }
      }
      dispatch(showSnack({
        text: 'Неизвестная ошибка на сервере. Повторите позднее...',
        type: SnackbarType.ERROR,
      }));
      return rejectWithValue(e.message);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  'subscriptionsList/update',
  async (subscriptionData, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.patch(`${RequestsPath.postSubscriptions}${subscriptionData.id}/`,
        subscriptionData
      );
      dispatch(showSnack({
        text: <div>
          Подписка изменена!
        </div>,
        type: SnackbarType.SUCCESS,
      }));
      return response;
    } catch (e) {
      if (e.code === 'ERR_BAD_REQUEST') {
        if (e.response.data.hashtags) {
          dispatch(showSnack({
            text: <div>
              Ошибка при изменении подписки. Неверно указаны скилы. <br /> {e.response.data.hashtags[0]}
            </div>,
            type: SnackbarType.ERROR,
          }));
          return rejectWithValue(e.message);
        }
      }
      dispatch(showSnack({
        text: 'Неизвестная ошибка на сервере. Повторите позднее...',
        type: SnackbarType.ERROR,
      }));
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  'subscriptionsList/delete',
  async (subscriptionId, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.delete(`${RequestsPath.postSubscriptions}${subscriptionId}/`);
      dispatch(showSnack({
        text: <div>
          Подписка удалена!
        </div>,
        type: SnackbarType.INFO,
      }));
      return response;
    } catch (e) {
      dispatch(showSnack({
        text: 'Ошибка при удалении подписки. Повторите позднее...',
        type: SnackbarType.ERROR,
      }));
      return rejectWithValue(e.message);
    }
  }
);
