import { createSlice } from '@reduxjs/toolkit';
import {
  getSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
} from '../../requests/postsSubscriptionRequests';
import { showSnack, SnackbarType } from './snackbarReducer';


const initialState = {
  data: [],
  loading: false,
};

const postSubscriptionReducer = createSlice({
  name: 'postSubscriptions',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSubscriptions.pending, state => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.loading = false;
      })
      .addCase(getSubscriptions.rejected, state => {
        state.data = [];
        state.loading = false;
      })
      .addCase(createSubscription.pending, state => {})
      .addCase(createSubscription.fulfilled, (state, { payload }) => {
        state.data.push(payload.data);
      })
      .addCase(createSubscription.rejected, (state, { dispatch }) => {})
      .addCase(updateSubscription.pending, state => {})
      .addCase(updateSubscription.fulfilled, (state, { payload }) => {
        const subscription_id = payload.config.url.split('/')[2];
        const index = state.data.findIndex(item => item.id == subscription_id);
        state.data[index] = payload.data;
      })
      .addCase(updateSubscription.rejected, state => {})
      .addCase(deleteSubscription.pending, state => {})
      .addCase(deleteSubscription.fulfilled, (state, { payload }) => {
        const subscription_id = payload.config.url.split('/')[2]
        state.data.splice(
          state.data.findIndex(item => item.id == subscription_id), 1
        );
      })
      .addCase(deleteSubscription.rejected, state => {});
  },
});

export default postSubscriptionReducer.reducer;
