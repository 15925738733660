const convertDate = (time) => {
  return new Date(time).toISOString();
};

export const prepareDataFilters = (data) => {
  const newData = data;

  const day = 86400000;
  const week = day * 7;
  const mounth = day * 30;
  const quarter = day * 90;

  const today = new Date().getTime();
  

  if (newData.company__work_location === 10)
    delete newData.company__work_location;
  
  if (newData.search === '') delete newData.search;

  if (newData.period === 10) {
    delete newData.from_created_date;
    delete newData.to_created_date;
  } else if (newData.period === 20) {
    newData.from_created_date = convertDate(today - day);
    newData.to_created_date = convertDate(today);
  } else if (newData.period === 30) {
    newData.from_created_date = convertDate(today - week);
    newData.to_created_date = convertDate(today);
  } else if (newData.period === 40) {
    newData.from_created_date = convertDate(today - mounth);
    newData.to_created_date = convertDate(today);
  } else if (newData.period === 50) {
    newData.from_created_date = convertDate(today - quarter);
    newData.to_created_date = convertDate(today);
  } else {
    newData.from_created_date = convertDate(new Date(newData.from_created_date).getTime());
    newData.to_created_date = convertDate(new Date(newData.to_created_date).getTime());
  }

  delete newData.period;
  return newData;
};
