import { Wrapper, Svg, Circle} from './Spinner.styled';

export const Spinner = () => {
  return (
    <Wrapper>
      <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <Circle cx="50" cy="50" r="45" />
      </Svg>
    </Wrapper>
  );
};
