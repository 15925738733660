import TabList from '@mui/lab/TabList';
import styled from 'styled-components/macro';
import { BREACKPOINTS } from '@styles/variables';

export const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TabListCustom = styled(TabList)`
  @media (max-width: ${BREACKPOINTS.md}) {
    & > div {
      & > div {
        overflow-x: scroll !important;
      }
    }
  }
`;
