import { createSlice } from '@reduxjs/toolkit';
import { getCompany, getCompanyComments, createComment } from '@requests/companyRequests';

const initialState = {
  data: {},
  comments: [],
  status: '',
  loading: false,
  commentLoading: false,
};

const companyReduser = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompany.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getCompanyComments.fulfilled, (state, action) => {
        state.comments = action.payload.data;
        state.commentLoading = false;
      })
      .addCase(getCompanyComments.pending, (state) => {
        state.commentLoading = true;
      })
      .addCase(getCompanyComments.rejected, (state) => {
        state.commentLoading = false;
      })
      .addCase(createComment.pending, (state) => {
        state.commentLoading = true;
      })
      .addCase(createComment.rejected, (state) => {
        state.commentLoading = false;
      })
      .addCase(createComment.fulfilled, (state, action) => {
        state.commentLoading = false;
      });
  },
});

export default companyReduser.reducer;
