import { LoadingButton } from '@mui/lab';
import { Rating } from '@mui/material';
import { CommentCreateWrapper, CommentFooter, CommentRating, CommentInput } from '../Company.styled';

export const CommentCreate = ({
  personalComment,
  textareaValue,
  handleChangeText,
  handleChangeRating,
  ratingValue,
  handleSubmit,
  loading,
}) => {
  return (
    <CommentCreateWrapper onSubmit={handleSubmit}>
      <CommentInput
        defaultValue={personalComment?.text}
        value={textareaValue}
        maxLength={2000}
        onChange={(e) => handleChangeText(e.target.value)}
        name="comment_text"
        placeholder="Напишите свой отзыв"
      />
      <CommentFooter>
        <CommentRating>
          <span>Оценка:</span>
          <Rating
            name="rating"
            defaultValue={Number(personalComment?.rating)}
            value={Number(ratingValue)}
            onChange={(event, newValue) => {
              handleChangeRating(newValue);
            }}
          />
        </CommentRating>

        <LoadingButton variant="contained" type="submit" disabled={!ratingValue || !textareaValue} loading={loading}>
          Отправить отзыв
        </LoadingButton>
      </CommentFooter>
    </CommentCreateWrapper>
  );
};
