import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, RequestsPath } from '@common/const';
import { setRefreshToken, setToken } from '@utils/checkAuth';
import { setStatusAuth } from '@store/reducers/userReducer';

export const checkAuth = createAsyncThunk('user/checkAuth', async (_, { rejectWithValue }) => {
  try {
    const response = API.get(RequestsPath.checkAuth);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const auth = createAsyncThunk('user/auth', async (params, { rejectWithValue }) => {
  try {
    return API.post(RequestsPath.login, {
      username: params.username,
      password: params.password,
    }).then((res) => {
      setToken(res.data.access);
      setRefreshToken(res.data.refresh);
      setStatusAuth(res.data.statusText);
      return res;
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const refreshToken = createAsyncThunk('user/refreshToken', async (refreshToken, { rejectWithValue }) => {
  try {
    const response = API.post(RequestsPath.refresh, {
      refresh: refreshToken,
    });
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
