import { useState, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  Button,
  Rating,
  AlertTitle,
} from '@mui/material';

import moment from 'moment/moment';

import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../Spinner';
import { SpennerWrapper } from '../Spinner/Spinner.styled';
import { changeSelected, resetMailling } from '../../store/reducers/maillingReducer';
import { setLimit, setOffset, setPage } from '../../store/reducers/postsReducer';
import { getPosts } from '../../requests/postsRequsts';
import {
  CompanyInfo,
  CompanyWrapper,
  TableCellCustom,
  TableContainerCustom,
  TableHeadCustom,
  TablePaginationCustom,
  TableRowCustom,
  ToolbarCustom,
  HeadPost,
  Skills,
  Author,
  PostDate,
} from './TableCustom.styled';

export const TableCustom = memo(({ data }) => {
  const postsData = useSelector((state) => state.posts);

  // const [order, setOrder] = useState("asc");
  // const [orderBy, setOrderBy] = useState("date");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const testRef = useRef(null);
  const scrollToElement = () => testRef.current.scrollIntoView();

  function createData(id, author_id, author, message, date, company, skills, allow_mailing) {
    return {
      id,
      author_id,
      author,
      message,
      date,
      company,
      skills,
      allow_mailing,
    };
  }

  const rows = data.map((item) => {
    return createData(
      item.id,
      item.author_id,
      item.author,
      item.text,
      moment(item.date_created).format('DD.MM.YYYY HH:mm'),
      item.company,
      item.skills,
      item.allow_mailing,
    );
  });

  const headCells = [
    {
      id: 'author_id',
      numeric: true,
      disablePadding: false,
      label: 'Текст запроса',
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Создан',
    },
  ];

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHeadCustom>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>

          <TableCell
            key={headCells[0].id}
            align={'left'}
            padding={headCells[0].disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCells[0].id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCells[0].id}
              direction={orderBy === headCells[0].id ? order : 'asc'}
              onClick={createSortHandler(headCells[0].id)}
            >
              {headCells[0].label}
              {orderBy === headCells[0].id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            key={headCells[1].id}
            align={'right'}
            padding={headCells[1].disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCells[1].id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCells[1].id}
              direction={orderBy === headCells[1].id ? order : 'asc'}
              onClick={createSortHandler(headCells[1].id)}
            >
              {headCells[1].label}
              {orderBy === headCells[1].id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHeadCustom>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <ToolbarCustom
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <>
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
              {numSelected} выбрано
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setSelected([]);
                dispatch(resetMailling());
              }}
              style={{
                width: '30%',
              }}
            >
              Снять выделение
            </Button>
          </>
        ) : (
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            Запросы
          </Typography>
        )}
      </ToolbarCustom>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleClick = (event, post) => {
    const selectedIndex = selected.indexOf(post.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, post.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    dispatch(changeSelected(newSelected));
  };

  const handleChangePage = (event, newPage) => {
    const new_offset = postsData.pagination.limit * newPage;

    dispatch(setOffset({ offset: new_offset }));
    dispatch(getPosts());
    dispatch(setPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    scrollToElement();
    const rowsPerPage = parseInt(event.target.value, 10);

    dispatch(setLimit({ limit: rowsPerPage }));
    dispatch(setOffset({ offset: 0 }));
    dispatch(getPosts());
    setRowsPerPage(rowsPerPage);
    dispatch(setPage({ page: 0 }));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = postsData.page > 0 ? Math.max(0, (1 + postsData.page) * rowsPerPage - rows.length) : 0;
  const renderMessage = (message) => {
    return { __html: message.replaceAll('\n', '<br />') };
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {data.loading && (
        <SpennerWrapper>
          <Spinner />
        </SpennerWrapper>
      )}

      {!data.loading && data ? (
        <Paper sx={{ width: '100%', mb: 2 }} ref={testRef}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainerCustom>
            <Table sx={{ minWidth: 320 }} aria-labelledby="tableTitle">
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRowCustom
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.author_id + Math.random()}
                      selected={isItemSelected}
                      style={{
                        verticalAlign: 'baseline',
                      }}
                    >
                      <TableCellCustom padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCellCustom>
                      <TableCellCustom component="th" id={labelId} scope="row" padding="normal">
                        {(row.company && (
                          <CompanyWrapper>
                            <CompanyInfo>
                              <Typography
                                variant="h5"
                                style={{
                                  fontWeight: '800',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '6px',
                                }}
                              >
                                {row.company.title}
                                {row.company.rating && (
                                  <Rating
                                    name="simple-controlled"
                                    value={Number(row.company.rating)}
                                    readOnly
                                    size="large"
                                    precision={0.5}
                                  />
                                )}
                              </Typography>

                              <Typography>
                                {'На Benchkiller с ' + moment(row.company.date_created).format('DD.MM.YYYY')}
                              </Typography>
                            </CompanyInfo>

                            <Button variant="contained" onClick={() => navigate(`/company/${row.company.chat_id}`)}>
                              Карточка компании
                            </Button>
                          </CompanyWrapper>
                        )) || (
                          <Alert severity="info" variant="outlined" style={{ marginBottom: '10px' }}>
                            <AlertTitle>Эта компания может не получить рассылку</AlertTitle>
                            Компания еще не зарегистрирована в системе.
                          </Alert>
                        )}

                        <HeadPost>
                          {!row.allow_mailing && row.company !== null ? (
                            <Alert severity="info" variant="outlined" style={{ marginBottom: '10px' }}>
                              <AlertTitle>Эта компания может не получить рассылку</AlertTitle>
                              Все участники компании запретили боту присылать сообщения
                            </Alert>
                          ) : (
                            <></>
                          )}
                          {row.author &&
                            // <Author>{ Number.isInteger(row.author) ? `<a href=''>` : `@${row.author}` }</Author>
                            <Author>
                              {
                                Number.isInteger(Number(row.author)) ?
                                  <a href={`tg://user?id=${row.author}`}>#id{row.author}</a>
                                  :
                                  <a href={`https://t.me/${row.author}`}>@{row.author}</a>
                              }
                            </Author>
                          }

                          {row.skills && (
                            <Skills>
                              {row.skills.map((skill, index) => (
                                <span key={skill.id + index}>{skill.name} </span>
                              ))}
                            </Skills>
                          )}
                        </HeadPost>

                        {row.message && <span dangerouslySetInnerHTML={renderMessage(row.message)}></span>}
                        <PostDate> Дата публикации: {row.date} </PostDate>
                      </TableCellCustom>

                      {/* <TableCellCustom align="right" style={{maxWidth:'400'}}>
                          {row.date}
                        </TableCellCustom> */}
                    </TableRowCustom>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRowCustom>
                    <TableCell colSpan={3} />
                  </TableRowCustom>
                )}
              </TableBody>
            </Table>
          </TableContainerCustom>
          <TablePaginationCustom
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={postsData.count}
            rowsPerPage={rowsPerPage}
            page={postsData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Элементов на странице"
          />
        </Paper>
      ) : (
        <Typography>Ничего не найдено</Typography>
      )}
    </Box>
  );
});
