import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { Helmet } from 'react-helmet';

// router, error handling, analytics, other wrappers goes here

const AppProviders = ({ children }) => (
  <>
    {/* <Helmet /> */}
    <BrowserRouter>{children}</BrowserRouter>
  </>
);

export default AppProviders;
