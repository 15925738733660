import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, RequestsPath } from '@common/const';

export const getLocationsList = createAsyncThunk('regionsList/get', async (_, { rejectWithValue }) => {
  try {
    const response = API.get(RequestsPath.locations);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
