import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedData: [],
};

const maillingReduser = createSlice({
  name: 'mailling',
  initialState,
  reducers: {
    changeSelected(state, action) {
      state.selectedData = action.payload;
    },
    resetMailling(state) {
      state.selectedData = [];
    },
  },
});

export const { changeSelected, resetMailling } = maillingReduser.actions;
export default maillingReduser.reducer;
