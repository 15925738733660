import { Rating } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { parceDate } from '@utils/parceDate';
import {
  CommentEditorWrapper,
  CommentFooter,
  CommentTitle,
  CommentRating,
  CommentDates,
  CommentInput,
} from '../Company.styled';

export const CommentEditor = ({
  handleSubmit,
  handleSetRating,
  handleChangeText,
  companyData,
  personalComment,
  ratingValue,
  textareaValue,
  loading,
}) => {
  return (
    <CommentEditorWrapper onSubmit={handleSubmit}>
      <CommentTitle>{companyData.data.title} (Редактирование отзыва)</CommentTitle>

      <CommentRating>
        <span>Оценка:</span>
        <Rating
          name="rating"
          defaultValue={Number(personalComment?.rating) || ratingValue}
          value={Number(ratingValue)}
          onChange={(event, newValue) => {
            handleSetRating(newValue);
          }}
        />
      </CommentRating>

      <CommentInput
        defaultValue={personalComment?.text}
        value={textareaValue}
        maxLength={2000}
        onChange={(e) => handleChangeText(e.target.value)}
        name="comment_text"
        placeholder="Напишите свой отзыв"
      />

      <CommentFooter>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={
            (textareaValue === personalComment?.text && ratingValue === personalComment?.rating) ||
            ratingValue === 0 ||
            !textareaValue
          }
          loading={loading}
        >
          Сохранить
        </LoadingButton>
        <CommentDates>
          <span>Отзыв создан: {parceDate(personalComment?.date_created)} </span>
          <span>Изменён: {parceDate(personalComment?.date_update)}</span>
        </CommentDates>
      </CommentFooter>
    </CommentEditorWrapper>
  );
};
