import { Home } from '@pages/Home';
import { Company } from '@pages/Company';
import { Mailing } from '@pages/Mailing';
import { Authorization } from '@pages/Authorization';
import { PostSubscription } from '@pages/PostSubscription';

import ROUTES from './routes';

const root = {
  path: ROUTES.root,
  element: Home,
  private: true,
};

const authorization = {
  path: ROUTES.authorization,
  element: Authorization,
};

const company = {
  path: ROUTES.company,
  element: Company,
  private: true,
};

const mailing = {
  path: ROUTES.mailing,
  element: Mailing,
  private: true,
};

const post_subscription = {
  path: ROUTES.post_subscription,
  element: PostSubscription,
  private: true,
};

const routesMap = [root, company, mailing, authorization, post_subscription];

export default routesMap;
