import { LoadingButton } from '@mui/lab';
import { Alert, Button, ButtonGroup, Typography } from '@mui/material';
import MarkdownIt from 'markdown-it';
import { useEffect, useState } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPost, getPosts } from '@requests/postsRequsts';
import { resetMailling } from '@store/reducers/maillingReducer';
import { changeStatus, resetFilters, setLimit, setOffset, resetStatus, setLookfor } from '@store/reducers/postsReducer';

export const Mailing = () => {
  const [textPost, setTextPost] = useState('');
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mailling = useSelector((state) => state.mailling.selectedData);
  const posts = useSelector((state) => state.posts);

  const mdParser = new MarkdownIt({
    breaks: true,
    typographer: true,
  });

  function handleEditorChange({ html, text }) {
    setValue(text);
    setTextPost(html);
  }

  const handleCreateMailing = () => {
    if (textPost !== '') {
      setError('');
      console.log(mailling)

      const selected_posts = []
      mailling.forEach(item => {
        selected_posts.push(posts.data.find(post => post.id === item));
      });

      console.log(selected_posts);
      const author = selected_posts.map(post => post.author);
      const post_ids = selected_posts.map(post => post.id);
      console.log(post_ids)
      const result = {
        data: {
          author,
          post_ids,
          text: textPost,
        },
      };

      dispatch(createPost(result));
    } else {
      setError('Поле поста не может быть пустым');
    }
  };

  useEffect(() => {
    if (!mailling.length) {
      navigate('/');
    }
  }, [mailling, navigate]);

  useEffect(() => {
    return () => {
      setValue('');
      setTextPost('');
      dispatch(changeStatus(null));
    };
  }, []);

  useEffect(() => {
    if (posts.status === 'error') {
      setError(`Ошибка! Попробуйте позже`);
    }

    if (posts.status === 'OK') {
      setError('');
      setValue('');
      setTextPost('');
    }
  }, [posts]);

  return (
    <>
      {/* {!!mailling.length && (
        <div>
          {mailling.map((item, index) => (
            <Typography key={index} variant="h5" style={{ marginBottom: '20px' }}>
              Создание рассылки
            </Typography>
          ))}
        </div>
      )} */}
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        Создание рассылки
      </Typography>

      <MdEditor
        style={{ height: '500px' }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
        value={value}
        canView={{
          menu: true,
        }}
        imageUrl={false}
      />

      {!!posts.status && posts.status === 'OK' ? (
        <Alert severity="success">Рассылка создана</Alert>
      ) : (
        <>{error && <Alert severity="error">{error}</Alert>}</>
      )}

      <Alert severity="info">
        Текст должен содержать не более 2000 символов
        <p>Кол-во символов: {value.length}</p>
      </Alert>

      {value.replace(/\s/g, '').length > 2000 && <Alert severity="warning">Вы исчерпали лимит символов</Alert>}

      <ButtonGroup sx={{ marginTop: '20px' }}>
        <LoadingButton
          variant="contained"
          onClick={() => handleCreateMailing()}
          loading={posts.loading}
          disabled={value.length > 2000}
        >
          Создать
        </LoadingButton>
        <Button
          onClick={() => {
            dispatch(resetStatus());
            dispatch(resetFilters());
            dispatch(setLimit({ limit: 0 }));
            dispatch(setOffset({ offset: 0 }));
            dispatch(setLookfor({ is_looking_for: true }));
            dispatch(getPosts());
            navigate('/');
            dispatch(resetMailling());
          }}
          variant="outlined"
        >
          Назад
        </Button>
      </ButtonGroup>
    </>
  );
};
