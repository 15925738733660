import { createSlice } from '@reduxjs/toolkit';
import { createPost, getPosts } from '@requests/postsRequsts';

const initialState = {
  data: [],
  status: null,
  loading: false,
  count: 0,
  page: 0,
  pagination: {
    offset: 0,
    limit: 10,
    is_looking_for: true,
  },
  filters: {
    post_from_company: true,
  },
  skillsFilters: null,
};

const postsReduser = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPage(state, action) {
      state.page = action.payload.page;
    },
    setLookfor(state, action) {
      state.pagination.is_looking_for = action.payload.is_looking_for;
    },
    setOffset(state, action) {
      state.pagination.offset = action.payload.offset;
    },
    setLimit(state, action) {
      state.pagination.limit = action.payload.limit;
    },
    setPostsFilters(state, action) {
      state.filters = action.payload;
    },
    setPostsSkillsFilters(state, action) {
      state.skillsFilters = action.payload;
    },
    changeStatus(state, action) {
      state.status = action.payload;
    },
    changeFilters(state, action) {
      Object.assign(state.filters, action.payload);
    },
    resetStatus: (state) => {
      state.status = null;
    },
    resetFilters: (state) => {
      state.filters = {};
    },
    setPostFromCompanyFilter: (state, action) => {
      state.filters.post_from_company = action.payload;
    },
    resetPagination: (state) => {
      state.pagination = {
        offset: 0,
        limit: 10,
        is_looking_for: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosts.pending, (state) => {
        state.data = [];
        state.loading = true;
      })
      .addCase(getPosts.fulfilled, (state, { payload }) => {
        state.data = payload?.data?.results;
        state.count = payload?.data?.count;
        state.loading = false;
      })
      .addCase(getPosts.rejected, (state) => {
        state.data = [];
        state.loading = false;
      })
      .addCase(createPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPost.rejected, (state, action) => {
        state.loading = false;
        state.status = action.error.message;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.loading = false;

        // запрос может быть 200, проверка на наличие поля failed (ошибка)
        if (action.payload?.data?.failed?.length) {
          state.status = 'error';
          return;
        } else {
          state.status = 'OK';
        }
      });
  },
});

export const {
  setPostsFilters,
  setPage,
  setLookfor,
  setOffset,
  setLimit,
  changeStatus,
  changeFilters,
  resetPagination,
  resetFilters,
  resetStatus,
  setPostsSkillsFilters,
  setPostFromCompanyFilter,
} = postsReduser.actions;

export default postsReduser.reducer;
