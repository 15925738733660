import { createSlice } from '@reduxjs/toolkit';
import { getLocationsList } from '../../requests/regionsRequests';

const initialState = {
  data: [],
  status: null,
  loading: false,
};

const regionsReducer = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsList.rejected, (state, action) => {
        state.loading = false;
        state.status = action.error.message;
      })
      .addCase(getLocationsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
      });
  },
});

export default regionsReducer.reducer;
