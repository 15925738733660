import styled from 'styled-components/macro';
import { COLORS, BREACKPOINTS } from '@styles/variables';
import { TextareaAutosize } from '@mui/material';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLORS.black};
  padding-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  h1 {
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export const LogoWrapper = styled.div`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div``;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  ${TextContainer} {
    width: 30%;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;

export const Comments = styled.div`
  width: 100%;
  padding: 0 0 20px;

  & > div {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CommentList = styled.div`
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #000;
`;

export const CommentText = styled.span`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const CommentTitle = styled.h4`
  margin-bottom: 10px;
`;

export const CommentRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    margin-right: 10px;
  }
`;

export const CommentInput = styled(TextareaAutosize)`
  font-family: 'Roboto', sans-serif;
  margin: 10px 0;
  width: 100%;
  min-height: 75px;
  border-radius: 10px;
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const CommentDates = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    &:first-child {
      margin-right: 5px;
    }
  }

  @media (max-width: ${BREACKPOINTS.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

export const CommentEditorWrapper = styled.form`
  width: 100%;
  background: #d9d9d9;
  border-radius: 12px;
  padding: 17px 20px;
  margin: 20px 0;
`;

export const CommentCreateWrapper = styled.form`
  width: 100%;
  background: #d9d9d9;
  border-radius: 12px;
  padding: 17px 20px;
  margin: 20px 0;
`;

export const CommentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREACKPOINTS.md}) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Comment = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  background: #d9d9d9;
  border-radius: 10px;

  & + & {
    margin-top: 20px;
  }

  ${CommentFooter} {
    margin-top: 20px;
  }

  ${CommentText} {
    margin-top: 10px;
  }

  ${CommentDates} {
    justify-content: flex-start;
  }
`;
