import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, RequestsPath } from '@common/const';

export const getPosts = createAsyncThunk('posts/getList', async (_, { rejectWithValue, getState }) => {
  const state = getState();
  try {
    const params = {
      ...state.posts.filters,
      ...state.posts.pagination,
      ...state.posts.skillsFilters,
    };
    const response = API.get(RequestsPath.posts, {
      params,
    });
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createPost = createAsyncThunk('posts/create', async (postData, { rejectWithValue }) => {
  try {
    const response = API.post(RequestsPath.createMailling, postData.data);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
