import { TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar } from '@mui/material';
import styled from 'styled-components/macro';
import { BREACKPOINTS, COLORS } from '@styles/variables';

export const CompanyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;

  @media (max-width: ${BREACKPOINTS.md}) {
    flex-direction: column;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREACKPOINTS.md}) {
    margin-bottom: 20px;
  }
`;

export const TableContainerCustom = styled(TableContainer)`
  overflow-x: hidden !important;

  @media (max-width: ${BREACKPOINTS.md}) {
    overflow-x: hidden;
  }
`;

export const TablePaginationCustom = styled(TablePagination)`
  @media (max-width: ${BREACKPOINTS.md}) {
    & > div {
      justify-content: center;
      align-items: center;
      flex-flow: row wrap;
    }
  }
`;

export const TableRowCustom = styled(TableRow)`
  @media (max-width: ${BREACKPOINTS.md}) {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    flex-flow: row wrap;

    th,
    td {
      border: none;
    }

    td:nth-of-type(1) {
      order: 1;
      width: 54px;
      padding-left: 0;
    }

    td:nth-of-type(2) {
      order: 2;
      width: 39%;
      text-align: left;
      padding: 12px;
    }

    th {
      order: 3;
    }
  }
`;

export const ToolbarCustom = styled(Toolbar)`
  @media (max-width: ${BREACKPOINTS.md}) {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 16px;

    button {
      width: 100% !important;
    }
  }
`;

export const TableHeadCustom = styled(TableHead)`
  @media (max-width: ${BREACKPOINTS.md}) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  tr {
    @media (max-width: ${BREACKPOINTS.md}) {
      display: flex;
      flex-flow: row wrap;
    }

    th {
      line-height: 2.1rem;

      @media (max-width: ${BREACKPOINTS.md}) {
        border: none;
        padding: 0;
      }
    }
  }
`;

export const TableCellCustom = styled(TableCell)`
  @media (max-width: ${BREACKPOINTS.md}) {
    width: 100%;
  }
`;

export const PostDate = styled.div`
  margin-top: 12px;
  font-weigth: 600;
  color: gray;
  text-align: right;
`;

export const HeadPost = styled.div`
  margin-bottom: 15px;
`;

export const Author = styled.span`
  font-weight: 600;
`;

export const Skills = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
  margin-bottom: 15px;

  span {
    background-color: ${COLORS.blue};
    padding: 10px 13px;
    color: ${COLORS.white};
    border-radius: 20px;
    margin-right: 5px;
    vertical-align: middle;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
`;
