import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  Dialog,
  Card,
  CardContent,
  CardActions,
  Radio,
  TextField,
  FormLabel,
  RadioGroup,
  FormControl,
  DialogTitle,
  DialogActions,
  Autocomplete,
  DialogContent,
  FormControlLabel,
} from '@mui/material';
import {ContentHead} from '@components';

import {API, RequestsPath} from '@common/const';
import {
  getSubscriptions,
  createSubscription,
  deleteSubscription,
  updateSubscription,
} from '../../requests/postsSubscriptionRequests';

export const PostSubscription = () => {
  const dispatch = useDispatch();
  const subsData = useSelector((state) => state.postSubscriptions);
  const userData = useSelector((state) => state.user);
  const [modalOpen, setModal] = useState(false);
  const [deleteConfirmOpen, setDeleteDialog] = useState(false);

  const [editableSubscription, setEditableSubscription] = useState(null);
  const [skills, setSkills] = useState([]);
  const [isLookingFor, setLookfor] = useState('avail');
  const [skillsOptions, setSkillsOptions] = useState([]);

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);

  const closeModal = () => {
    setSkills([]);
    setLookfor('avail');
    setModal(false);
    setEditableSubscription(null);
  };

  const deleteConfirmDialog = (subscription) => {
    setDeleteDialog(true);
    setEditableSubscription(subscription);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
    setEditableSubscription(null);
  };

  const editSubscription = (subsctiption) => {
    setLookfor(subsctiption.is_looking_for ? 'lookfor' : 'avail');

    const selectedSkills = subsctiption.hashtags.map(hashtag => ({
      label: hashtag.name,
      id: hashtag.id,
    }));
    setSkills(selectedSkills);
    setEditableSubscription(subsctiption);
    setModal(true);
  };

  const getAutocompleteHelps = (val) => {
    API.get(`${RequestsPath.skills}?name=${val}`).then(response => {
      setSkillsOptions(response.data.results.map(
        v => ({
          label: v.name,
          id: v.id,
        })
      ));
    });
  };

  const createSub = () => {
    const postType = (isLookingFor === 'lookfor');
    dispatch(createSubscription(
      {
        subscriber: userData.data.company.id,
        hashtags: skills.map(skill => skill.id),
        is_looking_for: postType,
      }
    ));
    closeModal();
  };

  const deleteSub = () => {
    dispatch(deleteSubscription(editableSubscription.id));
    closeDeleteDialog();
  };

  const updateSub = () => {
    const postType = (isLookingFor === 'lookfor');
    dispatch(updateSubscription({
      id: editableSubscription.id,
      hashtags: skills.map(skill => skill.id),
      is_looking_for: postType,
    }));
    closeModal();
  };

  return (<>
    <ContentHead>
      <Typography variant="h4" component="h1">
        Подписки на хэштеги
      </Typography>
      <Button
        variant="contained"
        onClick={() => {setModal(true);}}
        disabled={false}
      >
        Подписаться на хэштег
      </Button>
      <Dialog
        open={deleteConfirmOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Удалить подписку</DialogTitle>
        <DialogContent sx={{ paddingTop: '12px' }}> Вы точно хотите удалить подписку на хэштег? </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="info">Отмена</Button>
          <Button onClick={deleteSub} color="error">Удалить</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="Создать подписку на хэштег"
      >
        <DialogTitle variant="h5" component="div">
          { editableSubscription === null ?
            'Создать подписку'
            :
            'Изменить подписку'
          }
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            disablePortal
            freeSolo
            multiple
            options={skillsOptions}
            filterOptions={(x) => x}
            fullWidth
            style={{ marginBottom: '15px' }}
            onChange={(e, newValue) => setSkills(newValue)}
            value={skills}
            renderInput={
              (params) =>
                <TextField
                  {...params}
                  fullWidth
                  label="Скиллы"
                  onChange={(e, newValue) => getAutocompleteHelps(e.target.value)}
                />
            }
          />
          <FormControl>
            <RadioGroup
              name="is_looking_for"
              value={isLookingFor}
              onChange={e => {setLookfor(e.target.value);}}
            >
              <FormLabel>Тип поста</FormLabel>
              <FormControlLabel value="lookfor" control={<Radio />} label="Ищу специалистов" />
              <FormControlLabel value="avail" control={<Radio />} label="Предлагаю специалистов" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="info">Отмена</Button>
          <Button onClick={editableSubscription === null ? createSub : updateSub}>
            {
              editableSubscription === null ? 'Подписаться' : 'Изменить'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </ContentHead>
    {
      subsData.data.length > 0 ?
        subsData.data.map((subscription) => (
          <Card key={subscription.id} style={{marginBottom: '12px'}}>
            <CardContent>
              <Typography variant="h5" component="div">
                Подписка на хэштеги:
                {
                  subscription.hashtags.map(hashtag => (
                    <>&nbsp;#{hashtag.name}</>
                  ))
                }&nbsp;
                {
                  subscription.is_looking_for ?
                    <>(Ищу специалиста)</>
                    :
                    <>(Предлагаю специалиста)</>
                }
              </Typography>
              <Typography variant="body1" companent="div" style={{marginTop: '12px'}}>
                Уведомлений по подписке: {subscription.messages_sended}
              </Typography>
            </CardContent>
            <CardActions style={{float: 'right'}}>
              <Button
                size="small"
                onClick={() => {
                  editSubscription(subscription);
                }}
              >
                Изменить
              </Button>
              <Button
                size="small"
                color="error"
                onClick={() => {
                  deleteConfirmDialog(subscription);
                }}
              >
                Удалить
              </Button>
            </CardActions>
          </Card>
        ))
        :
        <Typography variant="h6" component="h2">
          У вас нет активных подписок на хэштеги
        </Typography>
    }
  </>);
};
