import { createSlice } from '@reduxjs/toolkit';

export const SnackbarType = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const initialState = {
  text: '',
  type: SnackbarType.INFO,
  show: false,
  duration: 5000,
};

const SnackbarReducer = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnack: (state, { payload }) => {
      state.show = true;
      state.text = payload.text;
      state.type = payload.type;
    },
    hideSnack: (state) => {
      state.show = false;
    },
  },
});

export const {
  showSnack,
  hideSnack,
} = SnackbarReducer.actions;

export default SnackbarReducer.reducer;
