import moment from 'moment/moment';
import { Button, Typography } from '@mui/material';
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@components';
import { getCompany, getCompanyComments, createComment } from '@requests/companyRequests';

import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LanguageIcon from '@mui/icons-material/Language';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ROUTES from '../../AppRouter/routes';
import { updateComment } from '../../requests/companyRequests';
import { Comments, CommentList, Head, Info, Logo, LogoWrapper, TextContainer, Wrapper } from './Company.styled';
import { CommentItem } from './components/Comment';
import { CommentEditor } from './components/CommentEditor';
import { CommentCreate } from './components/CommentCreate';
import { CommentsEmpty } from './components/CommentsEmpty';

export const Company = () => {
  // TODO: переделать хранение рейтинга
  const [personalComment, setPersonalComment] = useState({});
  const [textareaValue, setTextareaValue] = useState();
  const [rating, setRating] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const companyData = useSelector((state) => state.company);
  const userData = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (personalComment?.id) {
      dispatch(
        updateComment({
          text: textareaValue,
          rating: rating === 0 ? personalComment?.rating : rating,
          company_id: companyData.data?.id,
          comment_id: personalComment?.id,
        }),
      );
      setTextareaValue(textareaValue);
      setRating(rating);
    } else {
      dispatch(
        createComment({
          text: textareaValue,
          rating: rating,
          id: companyData.data?.id,
        }),
      );
    }

    if (companyData.data?.id) {
      dispatch(getCompanyComments(companyData.data?.id));
    }
  };

  // get company data
  useEffect(() => {
    if (id) {
      dispatch(getCompany(id));
    } else {
      navigate(ROUTES.root);
    }

    return () => {
      setRating(0);
      setTextareaValue('');
      setPersonalComment(companyData.comments.find((comment) => comment.author.id === userData.data.company?.id));
    };
  }, []);

  // get comment
  useEffect(() => {
    if (companyData.data?.id) {
      dispatch(getCompanyComments(companyData.data?.id));
    }
  }, [companyData.data?.id]);

  useEffect(() => {
    setPersonalComment(companyData.comments.find((comment) => comment.author.id === userData.data.company?.id));
  }, [companyData.comments]);

  if (companyData.loading && !companyData.data) return <Spinner />;

  return (
    <Wrapper>
      <Head>
        {companyData.data.logo && (
          <LogoWrapper>
            <Logo src={`${companyData.data.logo}`} alt="" />
          </LogoWrapper>
        )}

        <Typography variant="h3" component="h1">
          {companyData.data.title}
        </Typography>
      </Head>

      <Info>
        <TextContainer>
          <DateRangeIcon />
          <Typography style={{ fontWeight: 800 }}>Дата регистрации:</Typography>
          <Typography>{moment(companyData.data.date_created).format('DD.MM.YYYY')}</Typography>
        </TextContainer>

        <TextContainer>
          <LocationOnIcon />
          <Typography style={{ fontWeight: 800 }}>Регион: </Typography>
          {companyData.data?.work_location?.map((location, index) => (
            <Typography key={index}>{location.name}</Typography>
          ))}
        </TextContainer>

        <TextContainer>
          <InsertPhotoIcon />
          <Typography style={{ fontWeight: 800 }}>Портфолио: </Typography>
          <Typography component="a" href={companyData.data.portfolio} style={{ textDecoration: 'none' }}>
            {companyData.data.portfolio}
          </Typography>
        </TextContainer>

        <TextContainer>
          <LanguageIcon />
          <Typography style={{ fontWeight: 800 }}>Сайт компании:</Typography>
          <Typography component="a" href={companyData.data.url} style={{ textDecoration: 'none' }}>
            {companyData.data.url}
          </Typography>
        </TextContainer>

        <TextContainer>
          <AlternateEmailIcon />
          <Typography style={{ fontWeight: 800 }}>Email: </Typography>
          <Typography component="a" href={`mailto:${companyData.data.email}`} style={{ textDecoration: 'none' }}>
            {companyData.data.email}
          </Typography>
        </TextContainer>

        <TextContainer>
          <PeopleAltIcon />
          <Typography style={{ fontWeight: 800 }}>Регионы сотрудничества: </Typography>
          {companyData.data?.team_location?.map((region, index) => (
            <Typography key={index}>{region.name}</Typography>
          ))}
        </TextContainer>
      </Info>

      <Comments>
        <Typography variant="h3" component="h3" style={{ fontSize: 25 }}>
          Отзывы
        </Typography>

        {companyData.comments.find((comment) => comment.author.id === userData.data.company?.id) ? (
          <CommentEditor
            handleSubmit={handleSubmit}
            handleSetRating={setRating}
            handleChangeText={setTextareaValue}
            companyData={companyData}
            personalComment={personalComment}
            ratingValue={rating ? rating : personalComment?.rating}
            textareaValue={textareaValue}
            loading={companyData.commentLoading}
          />
        ) : (
          <CommentCreate
            handleSubmit={handleSubmit}
            personalComment={personalComment}
            handleChangeRating={setRating}
            handleChangeText={setTextareaValue}
            ratingValue={rating}
            textareaValue={textareaValue}
            loading={companyData.commentLoading}
          />
        )}

        <CommentList>
          {companyData.commentLoading ? (
            <Spinner />
          ) : (
            <>
              {!companyData.commentLoading && companyData.comments.length === 0 ? (
                <CommentsEmpty />
              ) : (
                companyData.comments.map((comment, index) => {
                  if (comment.author.id !== companyData.data?.id) {
                    return (
                      <Fragment key={index}>
                        <CommentItem comment={comment} />
                      </Fragment>
                    );
                  }

                  return <></>;
                })
              )}
            </>
          )}
        </CommentList>
      </Comments>

      <Button variant="contained" style={{ width: 100 }} onClick={() => navigate(ROUTES.root)}>
        Назад
      </Button>
    </Wrapper>
  );
};
