import { configureStore } from '@reduxjs/toolkit';
import postsReducer from '@reducers/postsReducer';
import userReducer from '@reducers/userReducer';
import companyReducer from '@reducers/companyReducer';
import maillingReducer from '@reducers/maillingReducer';
import regionsReducer from '@reducers/regionsReducer';
import postSubscriptionReducer from './reducers/postSubscriptionReducer';
import snackbarReducer from './reducers/snackbarReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    posts: postsReducer,
    company: companyReducer,
    mailling: maillingReducer,
    regions: regionsReducer,
    postSubscriptions: postSubscriptionReducer,
    snackbar: snackbarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.config', 'payload.headers', 'payload.request'],
      },
    }),
});

export default store;
