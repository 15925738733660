import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { API, RequestsPath } from '@common/const';

import moment from 'moment';
import 'moment/locale/ru';

import {
  Alert,
  Button,
  Switch,
  FormControlLabel,
  Box,
  Tab,
  Typography,
  ButtonGroup,
  MenuItem,
  FormControl,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { getLocationsList } from '@requests/regionsRequests';

import { TableCustom, ContentHead } from '@components';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { prepareDataFilters } from '@utils/prepareDataFilters';
import {
  setPostsFilters,
  setPage,
  setLookfor,
  setLimit,
  setOffset,
  resetPagination,
  resetFilters,
  setPostsSkillsFilters,
  setPostFromCompanyFilter,
} from '@store/reducers/postsReducer';
import { getPosts } from '@requests/postsRequsts';
import ROUTES from '../../AppRouter/routes';
import { FilterForm, TabListCustom } from './Home.styled';

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  moment.locale('ru');
  const localeMap = {
    ru: 'ru',
  };

  const postsData = useSelector((state) => state.posts);
  const mailling = useSelector((state) => state.mailling);
  const regions = useSelector((state) => state.regions.data);

  const searchPosts = postsData.data?.filter((post) => post.is_looking_for);
  const offerPost = postsData.data?.filter((post) => !post.is_looking_for);

  const [skillsFilters, setSkillsFilters] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [postFromCompany, setPostFromCompany] = useState(true);
  const [currentTab, setCurrentTab] = useState('1');
  const [region, setRegion] = useState(10);
  const [period, setPeriod] = useState(10);
  const [filters, setFilters] = useState();
  const [dateFrom, setDateFrom] = useState(new Date().toISOString());
  const [dateTo, setDateTo] = useState(new Date().toISOString());

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const handleChangeTab = (event, newValue) => {
    dispatch(setLimit({ limit: 10 }));
    dispatch(setOffset({ offset: 0 }));
    dispatch(setPage({ page: 0 }));
    dispatch(
      setLookfor({
        // eslint-disable-next-line eqeqeq
        is_looking_for: newValue == 1,
      }),
    );

    dispatch(getPosts());
    setCurrentTab(newValue);
  };

  const getAutocompleteHelps = (val) => {
    API.get(`${RequestsPath.skills}?name=${val}`).then(response => {
      setSkillsOptions(response.data.results.map(
        v => ({
          id: v.id,
          label: v.name,
        })
      ));
    });
  };

  const resetFiltersPage = () => {
    setPeriod(10);
    setRegion(10);
    reset();
    setFilters({});
  };

  const onSubmitFilters = (data) => {
    data.to_created_date = dateTo;
    data.from_created_date = dateFrom;
    const result = prepareDataFilters(data);
    setFilters(result);
  };

  useEffect(() => {
    console.log(skillsFilters);
    let filterValue = '';
    if (skillsFilters.length >= 2)
      filterValue = skillsFilters.reduce((p,v) => p.id + ',' + v.id);
    else if (skillsFilters.length === 1)
      filterValue = skillsFilters[0].id;

    console.log(filterValue);

    if (filterValue === ' ' || filterValue === '')
      dispatch(setPostsSkillsFilters({skills__id: null}));
    else
      dispatch(setPostsSkillsFilters({skills__id: filterValue}));
    dispatch(getPosts());
  }, [skillsFilters]);

  useEffect(() => {
    dispatch(getLocationsList());
    dispatch(getPosts());

    return () => {
      dispatch(resetPagination());
      dispatch(resetFilters());
      // reset();
    };
  }, []);

  useEffect(() => {
    const params = { ...filters };
    dispatch(setPostsFilters(params));
    dispatch(setPage({ page: 0 }));
    dispatch(setLimit({ limit: 10 }));
    dispatch(setOffset({ offset: 0 }));
    dispatch(setPostFromCompanyFilter(postFromCompany));
    dispatch(getPosts());
    // return () => reset();
  }, [filters, dispatch, reset]);

  const changePostFromCompany = (val) => {
    setPostFromCompany(val);
    dispatch(setPostFromCompanyFilter(val));
    dispatch(getPosts());
  };

  return (
    <Grid container spacing={2} flexDirection={{ xs: 'column-reverse', xl: 'row' }}>
      <Grid item xs={12} xl={8}>
        <ContentHead>
          <Typography variant="h4" component="h1">
            Поиск по постам
          </Typography>

          <Button
            variant="contained"
            onClick={() => navigate(ROUTES.mailing)}
            disabled={!mailling.selectedData?.length}
          >
            Сделать рассылку
          </Button>
        </ContentHead>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={currentTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <TabListCustom onChange={handleChangeTab} aria-label="search">
                <Tab label={`Ищу разработчиков`} value="1" sx={{ typography: 'body5' }} />
                <Tab label={`Предлагаю разработчиков`} value="2" />
              </TabListCustom>
            </Box>

            <TabPanel value="1" sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Alert variant="outlined" style={{ marginBottom: '15px' }}>
                Ниже отображаются посты, в которых участники сообщества ищут разработчиков на свои проекты
              </Alert>

              {!searchPosts ? (
                <Alert severity="warning" style={{ marginBottom: '15px' }}>
                  При использовании поиска по региону выводятся только посты пользователей с зарегистрированными в
                  сервисе компаниями , если вы хотите чтобы отображались посты всех пользователей, то в строке региона
                  сотрудничества выберите "Все регионы"
                </Alert>
              ) : (
                <> {searchPosts && <TableCustom data={searchPosts} />}</>
              )}
            </TabPanel>

            <TabPanel value="2" sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Alert variant="outlined" style={{ marginBottom: '15px' }}>
                Ниже отображаются посты, в которых участники сообщества предлагают своих разработчиков
              </Alert>

              {!offerPost ? (
                <Alert severity="warning" style={{ marginBottom: '15px' }}>
                  При использовании поиска по региону выводятся только посты пользователей с зарегистрированными в
                  сервисе компаниями , если вы хотите чтобы отображались посты всех пользователей, то в строке региона
                  сотрудничества выберите "Все регионы"
                </Alert>
              ) : (
                <> {offerPost && <TableCustom data={offerPost} />}</>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>

      <Grid item xs={12} xl={4}>
        <Typography variant="h4" component="h3" style={{ marginBottom: '20px' }}>
          Поиск
        </Typography>

        <FilterForm onSubmit={handleSubmit(onSubmitFilters)}>
          <TextField
            fullWidth
            defaultValue=""
            label="Поиск"
            inputProps={register('search')}
            style={{ marginBottom: '15px' }}
            variant="outlined"
          />
          <FormControl>
            <Autocomplete
              disablePortal
              freeSolo
              multiple
              options={skillsOptions}
              filterOptions={(x) => x}
              fullWidth
              style={{ marginBottom: '15px' }}
              onChange={(e, newValue) => setSkillsFilters(newValue)}
              autoHighlight={true}
              // isOptionEqualToValue={(option, value) => option.name === value.name}
              renderInput={
                (params) =>
                  <TextField
                    {...params}
                    fullWidth
                    label="Скиллы"
                    onChange={(e, newValue) => getAutocompleteHelps(e.target.value)}
                  />
              }
            />
          </FormControl>
          <FormControl>
            <TextField
              select
              fullWidth
              defaultValue={10}
              value={region}
              label="Регионы сотрудничества"
              inputProps={register('company__work_location', {
                onChange: (e) => setRegion(e.target.value),
              })}
              style={{ marginBottom: '15px' }}
              variant="outlined"
            >
              <MenuItem value={10}>Все регионы</MenuItem>
              {regions.length &&
                regions.map((region) => (
                  <MenuItem value={region.id} key={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>

          <FormControl>
            <TextField
              select
              fullWidth
              defaultValue={10}
              value={period}
              label="Период публикации"
              inputProps={register('period', {
                onChange: (e) => setPeriod(e.target.value),
              })}
              style={{ marginBottom: '15px' }}
              variant="outlined"
            >
              <MenuItem value={10}>За всё время</MenuItem>
              <MenuItem value={20}>День</MenuItem>
              <MenuItem value={30}>Неделя</MenuItem>
              <MenuItem value={40}>Месяц</MenuItem>
              <MenuItem value={50}>Квартал</MenuItem>
              <MenuItem value={60}>Произвольный период</MenuItem>
            </TextField>

            {period === 60 && (
              <FormControl margin="normal">
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localeMap.ru} localeText={localeMap}>
                  <DateTimePicker
                    label="От"
                    value={dateFrom}
                    inputProps={register('to_created_date')}
                    onChange={(newValue) => {
                      setDateFrom(newValue.toISOString());
                    }}
                    renderInput={(params) => <TextField {...params} style={{ marginBottom: '20px' }} />}
                    maxDate={new Date()}
                  />
                  <DateTimePicker
                    label="До"
                    value={dateTo}
                    inputProps={register('from_created_date')}
                    onChange={(newValue) => {
                      setDateTo(newValue.toISOString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
          </FormControl>

          <FormControlLabel
            control={
              <Switch
                defaultChecked
                value={postFromCompany}
                onChange={e => {
                  changePostFromCompany(e.target.checked);
                }}
              />
            }
            label="Отображать только посты с компаниями"
          />

          <ButtonGroup aria-label="outlined primary button group">
            <Button variant="contained" type="submit">
              Поиск
            </Button>
            <Button variant="contained" color="warning" onClick={resetFiltersPage}>
              Сбросить
            </Button>
          </ButtonGroup>
        </FilterForm>
      </Grid>
    </Grid>
  );
};
