import { Routes, Route } from 'react-router-dom';
import { memo } from 'react';
import { getToken } from '@utils/checkAuth';
import { MainLayout } from '@layouts/MainLayout';
import { useSelector } from 'react-redux';
import routesMap from './routesMap';
import PrivateRoute from './privateRoute';

const AppRouter = () => {
  const user = useSelector((state) => state.user);
  const token = getToken();

  const routesList = routesMap.map((route) => {
    const Page = route.element;
    const element = route.private ? (
      <PrivateRoute authorizationStatus={user.isAuth && token}>
        <MainLayout>
          <Page />
        </MainLayout>
      </PrivateRoute>
    ) : (
      <MainLayout>
        <Page />
      </MainLayout>
    );

    return <Route path={route.path} element={element} key={'_' + route.path} />;
  });

  return <Routes>{routesList}</Routes>;
};

export default memo(AppRouter);
