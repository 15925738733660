import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import { logout } from '@store/reducers/userReducer';
import { removeAuthData } from '@utils/checkAuth';
import ROUTES from '../../AppRouter/routes';
import { LogOut, LogoWrapper, Logo, OffersLink, Wrapper } from './Header.styled';

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo to="/">Benchkiller</Logo>

        {user.isAuth && (
          <>
            <OffersLink to="/">
              <ListIcon color="primary" />
              <span>Проекты и команды</span>
            </OffersLink>
            <OffersLink to="/post_subscription" style={{marginLeft: '12px'}}>
              <span>Подписки на хэштеги</span>
            </OffersLink>
          </>
        )}
      </LogoWrapper>

      {user.isAuth && (
        <LogOut
          onClick={() => {
            dispatch(logout());
            removeAuthData();
            navigate(ROUTES.authorization);
          }}
        >
          <ExitToAppIcon color="primary" />
          <span>Выйти</span>
        </LogOut>
      )}
    </Wrapper>
  );
};
