import { createSlice } from '@reduxjs/toolkit';
import { auth, checkAuth, refreshToken } from '../../requests/userRequests';

const initialState = {
  data: {},
  status: null,
  loading: false,
  isAuth: false,
};

const userReduser = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      state.data = {};
      state.status = null;
      state.isAuth = false;
    },
    setStatusAuth(state, action) {
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuth.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
        state.isAuth = true;
      })
      .addCase(auth.pending, (state) => {
        state.loading = true;
      })
      .addCase(auth.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message === 'Request failed with status code 401') {
          state.status = 'Не найдено активной учетной записи с указанными данными';
        } else {
          state.status = action.error.message;
        }
        state.isAuth = false;
      })
      .addCase(auth.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = 'OK';
        state.loading = false;
        state.isAuth = true;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.isAuth = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
        state.isAuth = true;
      });
  },
});

export const { logout, setStatusAuth } = userReduser.actions;
export default userReduser.reducer;
