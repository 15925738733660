export const BREACKPOINTS = {
  xxl: '1440px',
  xl: '1200px',
  lg: '992px',
  md: '768px',
  sm: '576px',
};

export const COLORS = {
  white: '#fff',
  black: '#000',
  blue: '#1976d2',
};
