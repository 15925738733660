import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, RequestsPath } from '@common/const';

export const getCompany = createAsyncThunk('getCompany/get', async (id, { rejectWithValue }) => {
  try {
    const response = API.get(`${RequestsPath.companyDetail}/${id}`);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getCompanyComments = createAsyncThunk('getCompanyComments/get', async (id, { rejectWithValue }) => {
  try {
    const response = API.get(`${RequestsPath.companyComments}/${id}/comment/`);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createComment = createAsyncThunk('createComment/post', async (params, { rejectWithValue }) => {
  try {
    const response = API.post(`${RequestsPath.companyComments}/${params.id}/comment/`, {
      text: params.text,
      rating: params.rating,
      company_id: params.id,
    });
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateComment = createAsyncThunk('updateComment/post', async (params, { rejectWithValue }) => {
  try {
    const response = API.put(`${RequestsPath.companyComments}/${params.company_id}/comment/${params.comment_id}}`, {
      text: params.text,
      rating: params.rating,
    });
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
