import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { getToken, getRefreshToken, setToken, setRefreshToken, removeToken } from '@utils/checkAuth';
import { RequestsPath } from '@common/const';
import { history } from '../index';

const REQUEST_TIMEOUT = 90000;

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: REQUEST_TIMEOUT,
});

axiosApiInstance.interceptors.request.use((config) => {
  const token = getToken();

  if (token && config.url !== RequestsPath.refresh) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    config.headers['Authorization'] = undefined;
  }

  return config;
});

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      // TODO: переделать интерсептор
      if (error.response.status !== StatusCodes.UNAUTHORIZED) {
        return Promise.reject(error);
      }

      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        const refreshToken = getRefreshToken();

        return axios
          .post(process.env.REACT_APP_BASE_URL + RequestsPath.refresh, {
            refresh: refreshToken,
          })
          .then((response) => {
            setToken(response.data.access);
            setRefreshToken(response.data.refresh);

            error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access;

            error.response.config.data = { refresh: response.data.refresh };
            return axiosApiInstance(error.response.config);
          })
          .catch((error2) => {
            removeToken();
            history.push('/authorization');
            return Promise.reject(error2);
          });
      }
    }

    throw error;
  },
);

export default axiosApiInstance;
