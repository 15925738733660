import styled from 'styled-components/macro';
import { BREACKPOINTS } from '@styles/variables';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREACKPOINTS.xl}) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  width: 68%;

  @media (max-width: 1220px) {
    width: 100%;
  }
`;

export const ContentHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    @media (max-width: 1220px) {
      font-size: 2rem;
    }
  }
`;

export const Filters = styled.div`
  width: 30%;

  @media (max-width: 1220px) {
    width: 100%;
    margin-bottom: 30px;
  }

  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
