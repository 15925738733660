import { Rating } from '@mui/material';
import { parceDate } from '@utils/parceDate';
import { Comment, CommentFooter, CommentTitle, CommentText, CommentDates } from '../Company.styled';

export const CommentItem = ({ comment }) => {
  return (
    <Comment>
      <CommentTitle>{comment?.author?.title}</CommentTitle>
      <Rating readOnly value={Number(comment?.rating)} />
      <CommentText>{comment?.text}</CommentText>
      <CommentFooter>
        <CommentDates>
          <span>Отзыв создан: {parceDate(comment?.date_created)} </span>
          <span>Изменён: {parceDate(comment?.date_update)}</span>
        </CommentDates>
      </CommentFooter>
    </Comment>
  );
};
