export const getToken = () => {
  const token = localStorage.getItem('Benchkiller_token');
  return token ?? '';
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem('Benchkiller_token_refresh');
  return refreshToken ?? '';
};

export const setToken = (data) => {
  localStorage.setItem('Benchkiller_token', data);
};

export const setRefreshToken = (data) => {
  localStorage.setItem('Benchkiller_token_refresh', data);
};

export const removeToken = () => {
  localStorage.removeItem('Benchkiller_token');
};

export const removeAuthData = () => {
  localStorage.removeItem('Benchkiller_token');
  localStorage.removeItem('Benchkiller_token_refresh');
};
