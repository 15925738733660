import styled, { keyframes } from 'styled-components/macro';

export const SpennerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
export const Wrapper = styled.div`
  width: 50px;
  height: 50px;
`;

const SvgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const CircleAnimation = keyframes`
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`;

export const Svg = styled.svg`
  animation: 2s linear infinite ${SvgAnimation};
`;

export const Circle = styled.circle`
  fill: transparent;
  stroke: #2f3d4c;
  stroke-width: 10;
  stroke-dasharray: 283;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  stroke-dashoffset: 75;
  animation: 2.4s ease-in-out infinite both ${CircleAnimation};
`;
