import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from '@components/Header';
import { getRefreshToken, getToken } from '@utils/checkAuth';
import { checkAuth } from '@requests/userRequests';
import { SnackbarType, showSnack } from '../../store/reducers/snackbarReducer';
import { CustomSnackbar } from '../../components/CustomSnackbar/CustomSnackbar';
import { Content } from './MainLayout.styled';



export const MainLayout = ({ children }) => {
  const dispatch = useDispatch();

  const token = getToken();
  const refreshToken = getRefreshToken();

  useEffect(() => {
    if (token && refreshToken) {
      dispatch(checkAuth());
    }
  }, []);


  return (
    <div className="layout">
      <Header />

      <Content>{children}</Content>

      <footer>© Benchkiller 2021 - 2023</footer>
      <CustomSnackbar />
    </div>
  );
};
