import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { COLORS } from '../../styles/variables';

export const Wrapper = styled.header`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.black};
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled(Link)`
  color: ${COLORS.white};
  text-decoration: none;
  margin-right: 20px;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.6;
  }
`;

export const LogOut = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  span {
    color: ${COLORS.white};
    margin-left: 10px;
  }
`;

export const OffersLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    display: none;
  }

  span {
    margin-left: 8px;
    color: ${COLORS.white};
  }
`;
