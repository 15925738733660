import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert } from '@mui/material';
import { Form, InputForm } from '@components/AuthorizationForm/AuthorizationForm.styled';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { auth } from '@requests/userRequests';
import ROUTES from '../../AppRouter/routes';

export const Authorization = () => {
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    username: '',
    password: '',
  });

  const onSubmit = (data) => {
    dispatch(auth(data));
  };

  useEffect(() => {
    if (user.isAuth) {
      navigate(ROUTES.root);
    }

    return () => reset();
  }, [navigate, reset, user.isAuth]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputForm
        placeholder="Ваш никнейм в telegram"
        error={errors.username ? true : false}
        {...register('username', {
          required: true,
        })}
      />

      <InputForm
        placeholder="Пароль"
        error={errors.password ? true : false}
        {...register('password', {
          required: true,
        })}
        type="password"
      />

      <Alert severity="info">
        Впервые у нас? Перейдите в нашего{' '}
        <a href="https://t.me/Benchkiller_bot" title="бот">
          бота
        </a>{' '}
        и сгенерируйте себе пароль.
      </Alert>

      {(message || user.status) && <Alert severity="error">{message || user.status}</Alert>}

      <LoadingButton variant="contained" type="submit" disabled={!isDirty || !isValid} loading={user.loading}>
        Войти
      </LoadingButton>
    </Form>
  );
};
