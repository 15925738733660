import { Input } from '@mui/material';
import styled from 'styled-components/macro';

export const Form = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 100%;
  }

  & > button {
    margin-top: 20px;
  }
`;

export const InputForm = styled(Input)`
  width: 100%;
  margin-bottom: 20px;
`;
