import { Snackbar, IconButton, Icon, Alert } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnack } from '../../store/reducers/snackbarReducer';


export const CustomSnackbar = (props) => {
  const dispatch = useDispatch();

  const snackbar = useSelector(state => state.snackbar);

  const closeSnackbar = () => {
    dispatch(hideSnack());
  };

  return <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    open={snackbar.show}
    autoHideDuration={snackbar.duration}
    onClose={closeSnackbar}
  >
    <Alert
      onClose={ closeSnackbar }
      variant="filled"
      severity={ snackbar.type }
      sx={{ width: '100%', minWidth: '250px' }}
    >
      {snackbar.text}
      {/* <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar}
      >
        <Icon><Close /></Icon>
      </IconButton>
      </div> */}
    </Alert>
  </Snackbar>;
};
