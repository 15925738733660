import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import './styles/index.css';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import store from './store';
import AppProviders from './AppProviders';
import AppRouter from './AppRouter';

Sentry.init({
  dsn: 'https://bedcd3bedd3a46509b43593adf0f3642@sentry.orca.codes/8',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory();

navigator.vibrate(200)

root.render(
  <Provider store={store}>
    <AppProviders>
      <AppRouter />
    </AppProviders>
  </Provider>,
);

export { history };
